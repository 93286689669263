.navbar {
  /* background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);  */
  background: #EE4054;
  height: 50px;
  display: flex;
  justify-content: center;
  /* justify-content: space-evenly; */
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0; 
  z-index: 999;

}

.home-headings-1{
  white-space: nowrap;
}
/* .search-bar {
  flex-grow: 1;
  max-width: 60%;
  min-width: 100px;
} */
.search-bar {
  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: 1;

}

.search{
  /* display: flex; 
  align-items: center; */

  padding-left: 10px;
  border: none;
  border-radius: 8px 8px 0px 0px;
  height: 37px;
  /* margin-top: -2.5px; */
  /* position: absolute; */
  width: 100%;
}
.search:focus {
  outline: none;
}
.search-2:focus {
  outline: none;
}
.search-2{
  /* display: flex; 
  align-items: center; */

  padding-left: 10px;
  border: none;
  border-radius: 8px;
  height: 37px;
  /* margin-top: -2.5px; */
  /* position: absolute; */
  width: 100%;
}
.no-result{
  display: none;
}
.result{
  display: flex;
  flex-direction: column;
  /* padding-left: 20px; */
  gap:10px;
  background: #FFFFFF;
  width: 100%;
  /* height: 50px; */
  top: 100%;
  position: absolute;
  border-radius: 0px 0px 8px 8px;
}
.result-wrapper{
  cursor: pointer;
  padding: 10px 20px;
}


.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  /* max-width: 1500px; */
  gap: 20px;
  width: 100%;
}

.navbar-logo {  
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  /* width: 147px; */
  /* height: 62px; */
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto); 
  /* grid-gap: 10px;

  text-align: center;
   width: 60vw; */
  justify-content: end;
  list-style: none;
  margin-right: 2rem;
}

/* .nav-item { */
  /* height: 80px; */
/* } */

.nav-links {
  color: #fff;
  display: flex;
  justify-self: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  text-align: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;


color: #FFFFFF;
}

.nav-links:hover {

  color: #FDA747;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}
.logo-img{
  height :100%; 
  width : 70%;
  /* border-radius:10px */
}
@media screen and (max-width: 1080px) {
  .search-bar{
    flex-grow: unset;
    width: 58%;

  }
}
@media screen and (max-width: 960px) {

  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 60px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #EE4054;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    /* padding: 2rem; */
    width: 100%;
    display: table;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nav-links:hover {
    /* background-color: #fff; */
    color: #FDA747;
    border-radius: 0;
  }

  .navbar-logo {
    top: 0;
    left: 0;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: -7px;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }
  .search-bar{
    flex-grow: unset;
    width: 50%;

  }
  .nav-links-mobile:hover {
    width: 100%;
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
  .country-dropdown{
    display: none;
  }

  .navbar-container{
    justify-content: start;
  }
  .logo-img{
    height :100%; 
    width : 7vh;
  }
  .navbar{
    height: 80px;
  }

}