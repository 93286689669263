.all-cont{
    display: flex;
    width: 100%;
    /* flex-wrap: wrap; */
    /* padding: 21px 71px; */
    
}
.footer-link:hover{
    color: #EE4054 !important;
}
.mid-cont{
    width: 100%;
    display: flex;
    
    /* flex-wrap: wrap; */
    flex-direction: column;
    background-color: white; 
    /* border-radius: 20px; */
    padding-bottom: 30px;
    box-shadow: 0px 0px 30px rgba(165, 165, 165, 0.25);
}
.footer-card{
    padding-left: 71px;
    
}
.last-footer{
    display: flex;
    align-items: center;
    gap: 10px;
    
    flex-wrap: wrap;
}
.foot-text{
    font-family: Montserrat;
    font-weight: 400;
    padding-left: 71px;
    font-size: 13px;
}
 
.socials{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 8px;

}

.img-socials{
    width: 36px;
    height: 36px;
    cursor: pointer;


}
.footer-item-container{
    display: flex;
    justify-content: space-between;
    background-color: white;
    /* width: 100%; */
    border-radius: 20px;
    
    padding-top: 10px;
    padding-left: 71px;
    padding-right: 71px;
    padding-bottom: 20px;
}
.footer-links{
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}
.footer-link-1{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 400px;
}
.links{
    font-family: Montserrat;
    font-weight: 400;
    cursor: pointer;
    text-decoration: none;
    color: #242424;
}
.links :hover{
    color: #EE4054 !important;
    font-family: Montserrat;
    font-weight: 400;
    cursor: pointer;
    text-decoration: none;
}
.footer-socials{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    
    flex-wrap: wrap;

}
.footer-socials label{
 
    font-style: normal;
    font-family: Montserrat;
    font-weight: 500;
}
.play{
    background-image: url('../../../public/play.png');
    width: 155.04px;
    height: 44.57px;
    background-size: cover;
    background-repeat: no-repeat;
    border: none;
    border-radius: 8px;
    cursor: pointer;
}
.app-btn{
    display: flex;
    gap: 24px;
    
    flex-wrap: wrap;
}
.app{
    background-image: url('../../../public/Appstore.png');
    width: 155.04px;
    height: 44.57px;
    background-size: cover;
    background-repeat: no-repeat;
    border: none;
    border-radius: 8px;
    cursor: pointer;
}


@media screen and (max-width :  1600px) {

    .footer-item-container{
      display: flex;
      flex-direction: column;
      gap: 40px;
      padding-left: 10px;
      padding-right: 10px;
      /* flex-wrap: wrap */
  }
  .all-cont{
    text-align: center;
    padding: 0px;
  }
  .mid-cont{
    padding: 0;
  }
  .app-btn{
    justify-content: center;
  }
  .last-footer{
    justify-content: center;
    align-items: center;
    
  }
  .foot-text{
    padding-left: 0;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .footer-card{
    padding-left: 0px;
  }
  .footer-links{
    /* flex-wrap: nowrap; */
    justify-content: center;
  }
   }