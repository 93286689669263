/* .App {
  text-align: center;
} */
@font-face {
  font-family: 'LEMONMILK';
  src: local('LEMONMILK'),
       url('./fonts/LEMONMILK-Bold.otf') format('woff'),
       url('./fonts/LEMONMILK-Light.otf') format('woff2');
}

*{
  font-family: 'Poppins';
  margin: 0;
}
/* .merchant-page-container{

} */
.add-member-btn{
  background: #FFF;
  width: 331px;
height: 43px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  color: #000;
font-family: Poppins;
font-size: 15px;
font-style: normal;
font-weight: 500; 
display: flex;
justify-content: space-between;
align-items: center;
padding: 0rem 2rem;
cursor: pointer;

}
.add-member-btn:hover{
  border : 2px solid #EE4054;
  color: #EE4054;
  box-shadow: none;
}

.mui-myselect > div{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.review-label{
  color: var(--light-success-light, #4CAF50);
  font-size: small;

}
.review-container{
  display: flex ;
  flex-direction: column;
  gap: 2px;
  padding-bottom: 18px;
  width: 60%;
}
.text-review-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  
}
.review-input-container{
  display: flex;
  flex-direction: column;
  width: 60%;
  gap: 20px;
  margin-bottom: 20px;
}
.review-head-1{
  color: #000;
  font-size: 18px;
  font-family: 'Poppins';
  font-weight: 600;
  margin: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.review-text{
  color: var(--light-text-secondary, rgba(0, 0, 0, 0.60));
  font-size: 14px;
}


@media screen and (max-width :  786px) {
  .review-container, .review-input-container{
    width: 100%;
  }
  .add-member-btn{
    width: auto;
  }
  .me
  .member-img{
  height: 24px;
  width: 24px;
}
  .reviewsubmit-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  .review-head-1{
    flex-direction: column;
    align-items: start;
  }
}
.merchant-coupon-container{
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  padding: 10px;
}




.voucher-container{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.notice-div{
  background: white;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.17);
}
.user-render-container{
  width: 100%;
}
.purchased-coupons{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 50px;
  padding: 33px;
  background: white;
  border-radius: 5px;
}
.redeemed-coupons{
  display: flex;
  gap: 50px;
  padding: 33px;
  background: white;
  justify-content: space-evenly;
  flex-wrap: wrap;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.17);
  border-radius: 5px;
}

.purchased-coupons .coupon-item-container .coupon-end{
  display: none;
}

.purchased-coupons .coupon-item-container .giftbtn {
  display: none;
}



@media screen and (max-width :  1430px) {
  .notice-div{
      height: auto;
  }
  .home-headings-10{
    text-align: center;
  }
 
}

























.sign-page-div{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.signup-field-container{
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.sign-up-form{
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 530px;
  /* height: 500px; */
  padding: 65px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
  border-radius: 13px;
  gap: 15px;
}
.signin-field-container{
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.switcher-div {
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #E7E7E7;    
}
.switcher-div > div{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
}

.login-div{
  width: 50%;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  background: #EE4054;
  color: #fff;
}
.signup-div{
  width: 50%;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

}
.loginEmail > input,
.loginPassword > input {
  padding: 1vmax 4vmax;
  padding-left: 10px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.267);
  border-radius: 4px;
  font-family: 'Montserrat';
font-style: normal;
  outline: none;
}

@media screen and (max-width : 786px) {
  .sign-up-form{
      width: 90%;
      padding: 20px;
  }    
}




















.tab-coupon{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
}
.new-class-user{
  flex-direction: column;
  padding: 21px 71px;
}
.new-class{
  width: 300px;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.max-width-container{
  max-width: 1920px;
  min-height: 1020px;
  margin: 0 auto;
  /* background: #F6F6F6; */
  align-items: center;
}

.user-page-div{
  padding: 21px 71px;
  display: flex;
  gap: 31px;
}
.menu-item-container{
  list-style: none;
  
}

.menu-user{
  width: 304px;
  min-width: 304px;
  background: #fff;
  padding: 11px 30px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.17);
border-radius: 5px;
height: 309px;
}
.privacy-detail{
  padding: 100px;
}
.menu-item{
  padding: 20px;
  color: rgba(23, 23, 23, 0.42);
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  cursor: pointer;
}
.menu-item-active{
  padding: 20px;
  /* color: rgba(23, 23, 23, 0.42); */

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #EE4054;

  cursor: pointer;
}
.menu-item:hover{
  /* padding: 20px; */
  /* color: rgba(23, 23, 23, 0.42); */
  color: #EE4054;


}

.home-headings-1{
  /* font-family: 'Bebas Neue'; */
  text-transform: capitalize;
  font-size: 40px;
  padding: 20px 71px 0px;
  align-items: center;
  margin: 0px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media screen and (max-width :  786px) {
  .home-headings-1{
    font-size: 28px;
    text-align: center;
    padding: 0px;
  }  
  .merchant-page-container{
    justify-content: center;
    align-items: center;
  }
  .merchant-detail-container{
    justify-content: center;
    align-items: center;
  }
  .privacy-detail{

    padding: 10px;
  }

  .new-class{
    width: 120px;
  }
  .new-class-user{
    padding: '0px' !important;
  }
  .coupon-tabs{
    padding: 10px 10px !important  ;
    /* text-align: center; */
    align-items: center;
  }
  .user-reset-btn{
    display: flex;
    justify-content: center;
  }
  
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
